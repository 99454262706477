import React from "react";
import Layout from "./layout";
import { Routes, Route } from "react-router-dom";
import PublicRouter from "./hoc/PublicRouter";
import PrivateRoute from "./hoc/PrivateRouter";
import NotFound from "./pages/NotFound";


const App = () => {
    return (
        <Layout >
            <Routes>
                {PublicRouter()}
                {PrivateRoute()}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Layout>
    )
}

export default App
