import { combineReducers } from 'redux';
import loginReducer from '../auth/reducer';
import userReducer from "../user/reducer";
import specialists from "../specialists/reducer";
import {settingReducer} from "../settings/reducer";
import postReducer from '../post/reducer'
import organizationReducer from "../supportOrganizations/reducer";
import eventsAndActivitiesReducer from "../eventsAndActivities/reducer";
import promotionReducer from '../promotionUnits/reducer';
import hashTagReducer from '../HashTags/reducer';
import groupReducer from '../groups/reducer'
import groupSuggestiveHashtagReducer from '../groupSuggestiveHashtags/reducer'

export default combineReducers({
 loginReducer,
    userReducer,
    specialists,
    settingReducer,
    postReducer,
    organizationReducer,
   eventsAndActivitiesReducer,
   promotionReducer,
   hashTagReducer,
   groupReducer,
   groupSuggestiveHashtagReducer
})