import { lazy } from "react";

//privateRouteAdmin
const BasicUserView = lazy(() => import("../pages/User/BasicUserView"))
const SpecialistsBasicView = lazy(() => import("../pages/Specialists/BasicView"))
const AddPhysician = lazy(() => import("../pages/Specialists/AddPhysician"))
const SpecialistsAdvanceView = lazy(() => import("../pages/Specialists/AdvanceView"))
const Organizations = lazy(() => import("../pages/Organizations"))
const AdvanceUserView = lazy(() => import("../pages/User/AdvanceUserView"))
const SpecialistPostDetail = lazy(() => import("../pages/SpecialistPostDetails"))
const Settings = lazy(() => import("../pages/Settings"))
const PromotionUnits = lazy(() => import("../pages/PromotionUnits/AddPromorionUnits"))
const DuplicatePromotionUnit = lazy(() => import("../pages/PromotionUnits/DuplicatePromotionUnit"))
const EditPromotionUnits = lazy(() => import("../pages/PromotionUnits/editPromotionUnits"))
const ArcPromotionUnits = lazy(() => import("../pages/PromotionUnits/ArcPromotionUnits"))

const Post = lazy(() => import("../pages/Post"));
const SupportOrganizations = lazy(() => import("../pages/SupportOrganizations"));
const AddOrganizations = lazy(() => import("../pages/SupportOrganizations/AddOrganizations"));
const AddOrganizationWiseAddEvent = lazy(() => import("../pages/SupportOrganizations/AddOrganizations/AddOrganizationWiseAddEvent"));
const OrganizationWiseEventList = lazy(() => import("../pages/SupportOrganizations/OrganizationWiseEventList"));
const EditOrganization = lazy(() => import("../pages/SupportOrganizations/EditOrganization"));
//

const EventsAndActivities = lazy(() => import("../pages/EventsAndActivities"));
const PromotionUnitsActive = lazy(() => import("../pages/PromotionUnits"));
const PromotionUnitsArchieved = lazy(() => import("../pages/PromotionUnits/ArchievedPromotion"));
const PromoteSpecialist = lazy(() => import("../pages/PromotionUnits/PromoteSpecialist"));
const PromoteORG = lazy(() => import("../pages/PromotionUnits/PromoteORG"));
const PromoteEVENT = lazy(() => import("../pages/PromotionUnits/PromoteEVENT"));
const ArchivedEventsList = lazy(() => import("../pages/EventsAndActivities/ArchivedEventsList"));
const AddEventAndActivities = lazy(() => import("../pages/EventsAndActivities/AddEventAndActivities"));
const EditEventAndActivity = lazy(() => import("../pages/EventsAndActivities/EditEventAndActivity"));

//publicRouteAdmin
const Login = lazy(() => import("../pages/Login"))
const ChangePassword = lazy(()=>import("../pages/ChangePassword"))
const ResetPassword = lazy(()=>import("../pages/ResetPassword/ResetPassword"))
const ResetSuccessScreen = lazy(()=>import("../pages/ResetPassword/ResetSuccessScreen"))
const PhysicianDetails = lazy(() => import("../pages/PhysicianDetails"))

//Hashtags
const HashTags = lazy(() => import('../pages/Settings/HashTags'))
const HashGroups = lazy(() => import('../pages/Settings/HashGroups'))

//Groups
const GroupsList = lazy(() => import('../pages/Groups'))
const GroupsSuggestiveHashtagList = lazy(() => import('../pages/Groups/GroupsSuggestiveHashtag'))

//Broadcast
const Broadcast = lazy(() => import('../pages/Broadcast'))

export const publicRouteAdmin = [
    { path: '/', Component: Login, title: "Login" },
    {path: '/reset-password',Component: ResetPassword, title: 'Reset Password'},
    {path: '/reset-password-landing',Component: ResetSuccessScreen, title: 'Reset Password'},
    {path: '/details',Component: PhysicianDetails, title: 'Physician Details'}

]
//AddPhysician
export const privateRouteAdmin = [
    { path: '/post', Component: Post, title: "Post" },
    { path: '/specialists/add', Component: AddPhysician, title: "Add Physician" },
    { path: '/support-organizations/event/:id', Component: OrganizationWiseEventList, title: "Add Event & Activities" },
    { path: '/support-organizations/add-event', Component: AddOrganizationWiseAddEvent, title: "Add Event & Activities" },
    { path: '/events-and-activities/add', Component: AddEventAndActivities, title: "Add Event & Activities" },
    { path: '/support-organizations', Component: SupportOrganizations, title: "Support Organizations" },
    { path: '/support-organizations/edit/:id', Component: EditOrganization, title: "Edit Support Organizations" },
    { path: '/support-organizations/add', Component: AddOrganizations, title: "Support Organizations" },
    { path: '/events-and-activities/active', Component: EventsAndActivities, title: "Active Events & Activities" },
    { path: '/events-and-activities/edit/:id', Component: EditEventAndActivity, title: "Edit Events & Activities" },
    { path: '/events-and-activities/archived', Component: ArchivedEventsList, title: "Events&Activities" },
    { path: '/users/basicView', Component: BasicUserView, title: "User" },
    { path: '/users/advanceView', Component: AdvanceUserView, title: "User" },
    { path: '/specialists/basicView', Component: SpecialistsBasicView, title: "Specialists" },
    { path: '/specialists/advanceView', Component: SpecialistsAdvanceView, title: "Specialists" },
    { path: '/organizations', Component: Organizations, title: "Organizations" },
    { path: '/specialists-details/:id', Component: SpecialistPostDetail, title: "Specialist Post Detail" },
    { path: '/change-password', Component: ChangePassword, title: "Change Password" },
    { path: '/settings', Component: Settings, title: "Settings" },
    { path: '/promotion-units/active', Component: PromotionUnitsActive, title: "Promotion Units" },
    { path: '/promotion-units/archived', Component: PromotionUnitsArchieved, title: "Promotion Units" },
    
    { path: '/promotion-units/add', Component: PromotionUnits, title: "Promotion Units" },
    { path: '/promotion-units/add/:id', Component: DuplicatePromotionUnit, title: "Promotion Units" }, //duplicate promotion-unit
    { path: '/promotion-units/edit/:id/:userId', Component: EditPromotionUnits, title: "Promotion Units" },
    { path: '/promotion-units/archived/:id/', Component: ArcPromotionUnits, title: "Promotion Units" },
    { path: '/promote-Specialist/add', Component: PromoteSpecialist, title: "Add Promotion Units" },
    { path: '/Promote-ORG/add', Component: PromoteORG, title: "Add Promote ORG" },
    { path: '/Promote-EVENT/add', Component: PromoteEVENT, title: "Add Promote EVENT" },

    { path: '/hashtags' ,Component : HashTags, title: 'HashTags'},
    { path: '/hashgroups' ,Component : HashGroups, title: 'HashGroups'},

    { path: '/groups' , Component : GroupsList, title: 'Groups'},
    { path: '/groups-suggestive-hashtag' , Component : GroupsSuggestiveHashtagList, title: 'Groups Suggestive Hashtag'},

    {path: '/broadcast' , Component : Broadcast , title: 'Broadcast'}
]