import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/sass/app.scss';
import 'antd/dist/antd.css';
import store from "./redux/store";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import Loader from "./component/Loader";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<Loader className={'content-loader'}/>}>
                    <App  />
                </Suspense>
            </ErrorBoundary>
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
