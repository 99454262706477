
const initialState = {
    hashTagName:'',
    hashTagStatus:''
}

export default function hashTagReducer(state=initialState,{type,payload}){
    switch (type) {
        case 'HASHTAG_NAME':{
            return{
                ...state,
                hashTagName:payload
            }
        }
        case 'HASHTAG_STATUS':{
            return{
                ...state,
                hashTagStatus:payload
            }
        }
        case 'CLEAR_HASHTAG_FILTER':{
            return{
                ...state,
                hashTagStatus:'',
                hashTagName:''
            }
        }
        default:{
            return{ ...state}
        }
    }
}