import { links } from "../../SideBarArray";
import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    let navigate = useNavigate();
    return (
        <div className="sidebar">
            {
                links.map((link, i) => {
                    if ((window.location.pathname.includes('/users') && link.route.includes("/users"))
                        || (window.location.pathname.includes('/specialists') && link.route.includes("/specialists"))
                        || (window.location.pathname.includes('/support-organizations') && link.route.includes("/support-organizations"))
                        ||  (window.location.pathname.includes('/events-and-activities') && link.route.includes("/events-and-activities"))
                        ||  (window.location.pathname.includes('/promotion-units') && link.route.includes("/promotion-units"))
                    ) {
                        return <ul className="list_unstyled" key={i}>
                            <li onClick={() => navigate(link.route)} className={"active"} ><span>{link.name}</span></li>
                        </ul>
                    } else {
                        return <ul className="list_unstyled" key={i}>
                            <li onClick={() => navigate(link.route)} className={((link.route === window.location.pathname) || (link.route === '/settings' && window.location.pathname === '/hashtags') || (link.route === '/settings' && window.location.pathname === '/hashgroups') || (link.route === '/groups' && window.location.pathname === '/groups-suggestive-hashtag')) ? "active" : ""} ><span>{link.name}</span></li>
                        </ul>
                    }
                })
            }
        </div>
    )
};

export default Sidebar

