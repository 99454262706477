
const initialState = {
    endDateFilter: {},
    startFilter: {},
    startArchivedFilter: {},
    endArchivedFilter: {}
}

export default function eventsAndActivitiesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'GET_START_EVENT_DATE_FILTER': {
            return {
                ...state,
                startFilter: payload
            }
        }
        case 'GET_END_EVENT_DATE_FILTER': {
            return {
                ...state,
                endDateFilter: payload
            }
        }
        case 'GET_START_EVENT_DATE_ARCHIVED_FILTER': {
            return {
                ...state,
                startArchivedFilter: payload
            }
        }
        case 'GET_END_EVENT_DATE_ARCHIVED_FILTER': {
            return {
                ...state,
                endArchivedFilter: payload
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                endDateFilter: {},
                startFilter: {},
                startArchivedFilter: {},
                endArchivedFilter: {}
            }
        }
        default:
            return state;
    }
}