import { Decryption, Encryption } from "../../../utils";
import Cookies from 'js-cookie'
import { LOGIN_SUCCESS } from "../../actionTypes";

export const updateUserDetails = (data) => async (dispatch, getState, api) => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
    })
}

export const userLogin = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/admin/login", payload)
        .then((res) => {
            if (res.status === 200) {
                localStorage.setItem("_crowdkure_ad_token", res.data.data.token)
                localStorage.setItem("userDetail", Encryption(JSON.stringify(res.data.data)))
                Cookies.set("x-access-token", res.data.data.token, { path: "/", expires: 1 / 2 });
                // dispatch({
                //     type: LOGIN_SUCCESS,
                //     currentUser: res.data.data,
                // })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};


export const changePassword = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/admin/change-password", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
};

export const ResetPasswordData = (payload) => async (dispatch, getState, api) => {
    return await api
        .post("/user/resetPassword", payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });

};
