import Cookies from 'js-cookie';
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import moment from "moment";
const CryptoJS = require('crypto-js');

export const getAccessToken = () => Cookies.get('x-access-token');
export const isAuthenticated = () => !!getAccessToken();

export const Encryption = (data) => {
    try {
        return CryptoJS.AES.encrypt(data, 'DFKTxfMLwjL9WUyNGJYU5200715A').toString();
    } catch (e) {
    }
}

export function Decryption(data) {
    try {
        let bytes =  CryptoJS.AES.decrypt(data, 'DFKTxfMLwjL9WUyNGJYU5200715A');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
    }
}

export function RequirePrivateAuth({ children }) {
    let auth = isAuthenticated();
    let location = useLocation();

    if (!auth) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

export function RequirePublicAuth({ children }) {
    let auth = isAuthenticated();
    let location = useLocation();

    if (auth) {
        return <Navigate to="/users/basicView" state={{ from: location }} replace />;
    }
    return children;
}

export const generateAvatar = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Draw background
    context.fillStyle = stringToHslColor(text);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 65px montserrat_regular";
    context.fillStyle = "#fff";
    context.textAlign = "center";
    context.textBaseline = "middle";

    let first = text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    let last = text?.split(' ')[1]?.charAt(0)?.toUpperCase();

    if (!last) {
        last = text?.split(' ')[0]?.charAt(1)?.toUpperCase() || text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    }
    if (!first) {
        first = "S";
        last = "U";
    }

    context.fillText(first + last, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
};


export const stringToHslColor = (str, s = 30, l = 80) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

// export const formatPhoneNumber = (str) => {
//     let value = str;
//     if(value){
//         value = value
//             .replace(/\D+/g, "")
//             .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
//         return value;
//     }
//     return str;
// };

// export function formatPhoneNumber(phoneNumberString) {
//     console.log(phoneNumberString,"phoneNumberString")
//     if (phoneNumberString.length > 10) {
//         // short answer but not format while typing
//         return phoneNumberString
//             .replace(/\D+/g, '')
//             .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
//     } else {
//         // this can format value while typing
//         let phone = phoneNumberString.replace(/\D/g, '');
//         const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
//         if (match) {
//             phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${match[3] ? '-' : ''}${match[3]}`
//             return phone;
//         }
//     }
// }


export const formatPhoneNumber = (str) => {
    let value = str;
    if(value){
        value = value
            .replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        return value;
    }
    return str;
};



// export function formatPhoneNumber(value) {
//     if (!value) return value;
//     const phoneNumber = value.replace(/[^\d]/g, '');
//     const phoneNumberLength = phoneNumber.length;
//     if (phoneNumberLength < 4) return phoneNumber;
//     if (phoneNumberLength < 7) {
//         return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
//     }
//     return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
// }

export const normalizePhone = (phone) => {
    let cleaned = phone.replace(/[^\w\s]/gi, '');
    return cleaned.replace(' ', '');
};

export const numberValidate = (value) => {
    if (!value.length) {
        return true
    } else {
        if (value.length <= 9) {
            return "Minimum  10 digits are allowed"
        }
    }
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

export const getDuration = (duration, date)=> {
    let seconds = duration.asSeconds();
    let minutes = duration.asMinutes();
    let hours = duration.asHours();
    let days = duration.asDays();
    let months = duration.asMonths();
    let years = duration.asYears();

    if (minutes >= 525600) {
        return `${years.toFixed()} year`
    } else if (months >= 1) {
        return moment(date).format('MMM DD YYYY')
    } else if (days < 2) {
        if (seconds < 60) {
            return "now"
        } else if (minutes <= 1) {
            return `${minutes?.toString()?.split('.')[0]} min`
        } else if (minutes > 1 && minutes < 60) {
            return  `${minutes?.toString()?.split('.')[0]} mins`
        } else if (minutes >= 60 && minutes < 120) {
            return `${hours?.toString()?.split('.')[0]} hour`
        } else if (minutes >= 120 && minutes < 1440) {
            return `${hours?.toString()?.split('.')[0]} hours`
        } else {
            return `${days?.toString()?.split('.')[0]} day`
        }
    } else if (days > 1) {
        return `${days?.toString()?.split('.')[0]} days`
    }

}

export const profileImages = (imgVal, commonImg) => {
    return  <img src={ imgVal ? (typeof imgVal === 'string' ? imgVal  :  URL.createObjectURL(imgVal)) : commonImg} alt='' />
}
export const disabledEndDate = (startDate,current) => {
    return (
        moment(startDate).valueOf() >= current.endOf('day').valueOf() ||
        current.startOf('day').valueOf() >= Date.now()
    );
};
export const disabledHours = (date) => {
    if(moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')){
        const hours = [];
        for (let i = 0; i < date.hour(); i += 1) hours.push(i);
        return hours;
    }
    return false
};

export const disabledMinutes = (date,selectedHour) => {
    if(moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')){
    const minutes = [];
    if (selectedHour.hour() === moment().hour()) {
        for (let i = 0; i < moment().minute(); i += 1) minutes.push(i);
    }
        return minutes;
    }
return false
};
export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

// export const urlify = (text) => {
//     let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
//     return text?.match(urlRegex)?.[0];
// };

export function urlify(text) {
    // let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    let urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?/g;
    return  text?.match(urlRegex)?.[0].replace(urlRegex, function(url,b,c) {
        let RegExp = /^https?:/
        let url2 = !RegExp.test(url) ?  'http://' +url : url;
        // let url2 = (c == 'www.') ?  'http://' +url : url;
        return url2;
    })
}

export function CommentFormatterDescription({ comment, handleClick }) {
    let commentArray = [];
    let people = comment?.tagPeople?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('|'))], [])
    let hashtags = comment?.hashtag?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('>'))], [])
    let commentUrl = comment.description.split(' ').filter((e) => {
        return e.toLowerCase().includes('https:') || e.toLowerCase().includes('http:') || e.toLowerCase().includes('www.')
    })
    let merged = [...(new Set([...people, ...hashtags, ...commentUrl])).values()];
    let tempStr = (Math.random(800000) * 100).toFixed(0);


    let res = merged.reduce((acc, cur) => {
        return acc.replaceAll(cur, `<${tempStr}>${cur}<${tempStr}>`)
    }, comment.description)

    res.split(`<${tempStr}>`).forEach(item => {
        let index = merged.findIndex(val => val === item);
        if (index > -1) {
            commentArray.push(<span onClick={() => handleClick(item)} className="blue-color">{item}</span>)
        } else {
            commentArray.push(item)
        }
    })

    return commentArray
}

export function CommentFormatter({ comment, handleClick }) {
    let commentArray = [];
    let people = comment?.tagPeople?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('|'))], [])
    let hashtags = comment?.hashtag?.reduce((acc, cur) => [...acc, cur.slice(cur.indexOf('|') + 1, cur.lastIndexOf('>'))], [])
    let commentUrl = comment.comment.split(' ').filter((e) => {
        return e.toLowerCase().includes('https:') || e.toLowerCase().includes('http:') || e.toLowerCase().includes('www.')
    })
    let merged = [...(new Set([...people, ...hashtags, ...commentUrl])).values()];
    let tempStr = (Math.random(800000) * 100).toFixed(0);


    let res = merged.reduce((acc, cur) => {
        return acc.replaceAll(cur, `<${tempStr}>${cur}<${tempStr}>`)
    }, comment.comment)

    res.split(`<${tempStr}>`).forEach(item => {
        let index = merged.findIndex(val => val === item);
        if (index > -1) {
            commentArray.push(<span onClick={() => handleClick(item)} className="blue-color">{item}</span>)
        } else {
            commentArray.push(item)
        }
    })

    return commentArray
}