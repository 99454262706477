
// this is all action

export const getAllUsersList = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/getAllUsers', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}

export const deleteUserList = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/deletePhysicianUser', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}

export const updateStatusUserList = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/updatePhysicianUserStatus', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}

export const filterSearch = (payload) => async (dispatch, getState, api) => {
    // dispatch({
    //     type: FILTER_SEARCH_LIST,
    //     payload: payload,
    // })
}

export const getAllReportByUserId = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/getAllReportByUserId', payload)
    .then((res) => {
        return res;
    })
        .catch((err) => {
            return err.response;
        });
}

export const getAllUserAdvanceDataView = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/getAllUserAdvanceDataView', payload)
    .then((res) => {
        return res;
    })
        .catch((err) => {
            return err.response;
        });
}

export const getAllReportByPostId = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/getAllReportByPostId', payload)
    .then((res) => {
        return res;
    })
        .catch((err) => {
            return err.response;
        });
}

export const MasterPasswordGenerater = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/createMasterPassword', payload)
    .then((res) => {
        return res;
    })
        .catch((err) => {
            return err.response;
        });
}
export const getAllDeletedUsersList = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/getAllDeletedUsers', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}
export const getAllActiveUser = () => async (dispatch, getState, api) => {
    return await api.get('/admin/getActiveUserCount').then((res) => {
        return res
    }).catch((err) => {
        return err.response
    })
}
export const sentUserInvitation = (payload) => async (dispatch, getState, api) => {
    return await api.post('/bizuser/sendBizCMSInvitation', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}
export const generateLoginToken = (payload) => async (dispatch, getState, api) => {
    return await api.post('/admin/generateLoginToken', payload)
    .then((res) => {
        return res;
    })
    .catch((err) => {
        return err.response;
    });
}

//------------------ filter action ------------------------------------------

export const getFlagFilter = (payload) => async (dispatch, getState, api) => {
    dispatch({
            type: 'GET_FLAG_FILTER',
        payload: payload,
    })
}
export const getLastLoggedOn = (payload) => async (dispatch) => {
    dispatch({
        type: 'GET_LAST_LOGGED_ON',
        payload: payload,
    })
}
export const getAddedOnFilter = (payload) => async (dispatch) => {
    dispatch({
        type: 'GET_ADDED_ON_DATE_FILTER',
        payload: payload,
    })
}
///---------------------------------- Advance User flag /admin/userFlagsByUserId ------------------------------------
export const getAdvanceFlagList = (payload) => async (dispatch, getState, api) => {
    return await api.post('admin/userFlagsByUserId', payload)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err.response;
        });
}

//----------------------------------
export const clearFilterData = () => {
    return {
        type: 'CLEARFILTERDATA',
    }
}

