let initialState = {
    setting_list: {}
}

export const settingReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'GET_SETTING_DATA': {
            let result =  payload.maintenanceModeData.reduce((acc,cur)=> ({...cur,...acc}))
            let androidApp = payload?.appVersionData?.filter((item) => ( item.appType === "android")).reduce((acc,cur)=>({...cur,acc}))
            let iosApp = payload?.appVersionData?.filter((item) => (item.appType === "ios")).reduce((acc,cur)=>({...cur,acc}))
            return {
                ...state,
                setting_list: {maintenanceModeData:{...result}, androidApp,iosApp, distanceData : payload.distanceData}
            }
        }
        default:
            return state
    }
}