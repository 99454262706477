const initialState ={
    flagFilter: {
        flagCount: '',
        sortingOrder: ''
    }
}

export default function groupReducer(state=initialState,{type,payload}) {
    switch (type) {
        case 'GET_FLAG_GROUP_FILTER': {
            return {
                ...state,
                flagFilter: payload
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                flagFilter: {
                    flagCount: '',
                    sortingOrder: ''
                },
            }
        }
        default: {
            return {...state}
        }
    }
}