import React, { useRef, useState, useEffect } from 'react';
import profile_pic from './../../assets/images/profile_pic.png';
import down_arrow from './../../assets/images/Down_arrow.svg';
import { matchPath, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/New_cancerGo_cms_logo.png";
import notifiaction_icon from "../../assets/images/notifiaction_icon.svg";
import refreshIcon from '../../assets/images/refresh_icon.svg'
import { getAllActiveUser } from '../../redux/user/action';
import Cookies from 'js-cookie';

const Header = ({ isAuthenticated, handleLogout, isMenuOpen, setIsMenuOpen, active }) => {
    const ref = useRef();
    const currentUser = useSelector(state => state?.loginReducer?.currentUser)
    let match = null;
    let password = null;
    const [activeUser,setActiveUser] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        // let loginToken = Cookies.get('x-access-token') || localStorage.getItem('_crowdkure_ad_token')
        if(isAuthenticated){
            handleRefresh()
        }
    }, [isAuthenticated])
    

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target) && e.target.id !== 'profileIcon') { setIsMenuOpen(false) }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => { document.removeEventListener("mousedown", checkIfClickedOutside) }
    }, [isMenuOpen])
    const settingScreenData = () => {
        setIsMenuOpen(false)
    }

    const handleRefresh = () => {
        dispatch(getAllActiveUser()).then(res => {
            if(res.data.success){
                setActiveUser(res.data.data)
            }
        })
    }

    return (
        <>
            <header>
                {
                    isAuthenticated &&
                    <div className="container_fluid">
                        <div className="row main_herader_row crowdkure_header_row ">
                            <div className="header px_auto">
                                <div className="crowdkure_logo">
                                    <img src={logo} alt="logo_img" />
                                </div>
                                <div className="header_profile">
                                    <div className='active_user'>
                                      <span className='active_user_status'></span>
                                      <span className='active_user_count'>Active USer Count: {activeUser}</span>
                                      <span className='active_user_refresh_img' onClick={handleRefresh}><img src={refreshIcon}></img></span>
                                    </div>
                                    <div className="profile">
                                        <span className="profile_name">{currentUser?.first_name + ' ' + currentUser?.last_name}</span>
                                        <span className="profile_pic" ><img id="profileIcon" onClick={() => setIsMenuOpen(!isMenuOpen)} src={profile_pic} alt="profile_pic" /></span>
                                    </div>
                                    <div className="drop_down">
                                        <div className="cursor_pointer" ><img id="profileIcon" onClick={() => setIsMenuOpen(!isMenuOpen)} src={down_arrow} alt="down_arrow" /></div>
                                        {
                                            isMenuOpen &&
                                            <ul className="list_unstyled drop_down_list" ref={ref}>
                                                <li><Link to="/change-password" className={window.location?.pathname === '/change-password' && "active"} onClick={() => settingScreenData()}> Change Password <span className="dot_blue d_none" /></Link></li>
                                                {/*<li><Link to="/settings" className={window.location?.pathname === '/settings' && "active"} onClick={() => settingScreenData()}>Settings <span className="dot_blue d_none" /></Link></li>*/}
                                                <li><a href="#" >Contact Support <span className="dot_blue d_none" /></a></li>
                                                <li><a className={active === 'logout' && " "} onClick={() => handleLogout()}>Log Out<span className="dot_blue d_none" /></a></li>
                                            </ul>
                                        }
                                    </div>
                                    {/* notofiactiob icon */}
                                    {/*<div className='notification_block'>*/}
                                    {/*    <img src={notifiaction_icon} alt='' />*/}
                                    {/*    <span>3</span>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </header>
        </>
    )
};

export default Header