const initialState = {
    addedOnFilter:{},
    eventByOrgStartDate:{},
    eventByOrgEndDate:{},
    orgEmail:[]
}
export default function organizationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'GET_ADDED_ON_DATE_FILTER_ORG' :{
            return {
                ...state,
                addedOnFilter: payload
            }
        }
        case 'GET_START_DATE_EVENT_By_ORG_FILTER':{
            return {
                ...state,
                eventByOrgStartDate: payload
            }
        }
        case 'GET_END_DATE_EVENT_By_ORG_FILTER':{
            return {
                ...state,
                eventByOrgEndDate: payload
            }
        }
        case 'SEARCH_ORG_EMAIL':{
            return {
                ...state,
                orgEmail: payload
            }
        }

        case 'CLEARFILTERDATA': {
            return {
                ...state,
                addedOnFilter:{},
            }
        }
        default:{
            return state;
        }
    }
}