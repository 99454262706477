
const initialState = {
    flagFilter: {
        flagCount: '',
        sortingOrder: ''
    },
    lastLogged: {},
    addedOnFilter: {}
}

export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'GET_FLAG_FILTER': {
            return {
                ...state,
                flagFilter: {
                    flagCount: payload?.flagCount,
                    sortingOrder: payload?.sortingOrder
                }
            }
        }
        case 'GET_LAST_LOGGED_ON': {
            return {
                ...state,
                lastLogged: payload
            }
        }
        case 'GET_ADDED_ON_DATE_FILTER': {
            return {
                ...state,
                addedOnFilter: payload
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                flagFilter: {
                    flagCount: '',
                    sortingOrder: ''
                },
                lastLogged: {},
                addedOnFilter: {}
            }
        }
        default:
            return state;
    }

}