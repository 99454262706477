import { LOGIN_SUCCESS } from '../../actionTypes';
const initialState = {
    currentUser: {}
}

export default function loginReducer(state = initialState, { type, payload }) {
    switch (type) {
        case LOGIN_SUCCESS: {
            return {
                ...state,
                currentUser: payload
            }
        }
        default:
            return state;
    }
}