export const links = [
    {
        id: 1,
        name: "users",
        route: "/users/basicView",
    },
    {
        id: 2,
        name: "Post",
        route: "/post",
    },
    {
        id: 3,
        name: "Physicians/specialists",
        route: "/specialists/basicView",
    },
    {
        id: 4,
        name: "support-organizations",
        route: "/support-organizations",
    },
    {
        id: 5,
        name: "events & activities",
        route: "/events-and-activities/active",
    },{
        id: 6,
        name: "Spotlight Units",
        route: "/promotion-units/active",
    },
    {
        id: 7,
        name: "Groups",
        route: "/groups",
    },
    {
        id: 8,
        name: "Broadcast",
        route: "/broadcast"
    },
    {
        id: 9,
        name: "Settings",
        route: "/settings",
    },
];