import { RequirePublicAuth } from "../../utils";
import { Route, useNavigate } from "react-router-dom";
import React from "react";
import { publicRouteAdmin } from "../../routes";

const PublicRouter = () => {
    let navigate = useNavigate();
    return (
        publicRouteAdmin.map(({ path, Component, title }, key) => {
            return (
                <Route
                    exact
                    path={path}
                    element={<RequirePublicAuth><Component navigate={navigate}/></RequirePublicAuth>}
                    key={key}
                />
            )
        })
    )
}
export default PublicRouter