const initialState = {
    promotionEmail: '',
    promotionType: '',
    promotionFormat: '',
    promoType: '',
    flagFilter: {
        flagCount: '',
        sortingOrder: ''
    },
}

export default function promotionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'PROMOTION_TYPE': {
            return {
                ...state,
                promotionType: payload
            }
        }
        case 'SEARCH_PROMOTION_EMAIL': {
            return {
                ...state,
                promotionEmail: payload
            }
        }
        case 'PROMOTION_FORMAT': {
            return {
                ...state,
                promotionFormat: payload
            }
        }
        case 'PROMO_TYPE_ADD': {
            return {
                ...state,
                promoType: payload
            }
        }
        case 'GET_FLAG_PROMO_FILTER': {
            return {
                ...state,
                flagFilter: payload
            }
        }

        case 'CLEARFILTERDATA': {
            return {
                ...state,
                promotionType: '',
                promotionFormat: '',
                flagFilter: {
                    flagCount: '',
                    sortingOrder: ''
                },
            }
        }
        default: {
            return { ...state }
        }

    }
}