import React, { Suspense, useEffect, useState } from 'react';
import Header from "./Header";
import Cookies from 'js-cookie';
import { Decryption, isAuthenticated } from "../utils";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../redux/auth/action";


const Layout = ({ children, history }) => {
    const dispatch = useDispatch()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [active, setActive] = useState(true);

    let navigate = useNavigate();


    const handleLogout = () => {
        setActive('logout')
        localStorage.removeItem('_crowdkure_ad_token')
        Cookies.remove('x-access-token', { path: "/" });
        setIsMenuOpen(false)
        navigate('/');
    };

    useEffect(() => {
        if (localStorage.getItem('userDetail') && isAuthenticated) {
            dispatch(updateUserDetails(Decryption(localStorage.getItem('userDetail'))))
        }
    }, [isAuthenticated(), localStorage.getItem('userDetail')]);


    return (
        <div className={'layout'}>
            <Header
                isAuthenticated={isAuthenticated()}
                handleLogout={handleLogout}
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                active={active}
            />
            <section className={isAuthenticated() ? "page_wrapper" : " inner_wrapper"}>
                {
                    isAuthenticated() && <Sidebar
                        history={history}
                    />
                }
                {
                    isAuthenticated() && !window.location.pathname.includes('specialist-details') &&
                    !window.location.pathname?.includes("change-password") && !window.location.pathname?.includes("settings") ?
                        <Suspense fallback={""}>
                            <div className="content_wrapper">
                                <div className="clinics">
                                    {/*<BreadCrumb />*/}
                                    {children}
                                </div>
                            </div>

                        </Suspense>
                        :
                        <Suspense fallback={""}>
                            {children}
                        </Suspense>
                }

            </section>
        </div>
    )
}
export default Layout