
const initialState = {
    filter: '',
    filterDate: {},
    flagFilter: {
        flagCount: '',
        sortingOrder: ''
    },
}

export default function postReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'FILTER_VISIBILITY_LIST': {
            return {
                ...state,
                filter: payload
            }
        }
        case 'FILTER_POST_DATE_FILTER': {
            return {
                ...state,
                filterDate: payload
            }
        }

        case 'CLEARFILTERDATA': {
            return {
                ...state,
                filter: '',
                filterDate: {},
                flagFilter: {
                    flagCount: '',
                    sortingOrder: ''
                },
            }
        }
        case 'GET_FLAG_POST_FILTER': {
            return {
                ...state,
                flagFilter: payload
            }
        }

        default:
            return state;
    }
}