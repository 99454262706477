const initialState ={
    flagFilter:{
        sortingOrder:''
    }
}

export default function groupSuggestiveHashtagReducer (state=initialState,{type,payload}) {
    switch (type) {
        case 'GET_GROUP_SORTING_FILTER':{
            return {
                ...state,
                flagFilter:payload
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                flagFilter: {
                    sortingOrder: ''
                },
            }
        }
        default:{
            return{ ...state }
        }
    }
}