const initialState = {
    physicianProfile: {},
    flagFilter:{
        flagCount: '',
        sortingOrder:''
    },
    lastLoggedPhy:{},
    addOnPhy:{},
    filterVisibilityPhy:'',
    managePostDateFilter:{},
    mensionList:{},
    HashTagList:{}
}

export default function specialistReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'PHYSICIAN_PROFILE_DETAILS': {
            return {
                ...state,
                physicianProfile: payload
            }
        }
        case 'GET_FLAG_PHY_FILTER':{
            return {
                ...state,
                flagFilter: {
                    flagCount:payload?.flagCount,
                    sortingOrder:payload?.sortingOrder
                }
            }
        }
        case 'GET_LOGGED_DATE_FILTER' : {
            return {
                ...state,
                lastLoggedPhy : payload
            }
        }
        case 'GET_ADDED_ON_DATE_FILTER_SPECIALIST' :{
            return {
                ...state,
                addOnPhy : payload
            }
        }
        case 'FILTER_VISIBILITY_LIST':{
            return {
                ...state,
                filterVisibilityPhy : payload
            }
        }
        case 'FILTER_DATE_POST_FILTER_LIST':{
            return {
                ...state,
                managePostDateFilter : payload
            }
        }
        case 'PHYSICIAN_ADD_MENSION':{
            let tempPayload = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id, type:cur.user_type[0].type ,display: cur.user_type[0].type === 'org' ? `@${cur.org_name}` : `@${cur.first_name} ${cur.last_name}`}]
            },[])}
            return {
                ...state,
                mensionList: tempPayload
            }
        }

        case 'PHYSICIAN_ADD_HASHTAG': {
            let temp = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id ,display: cur.name}]
            },[])}            
            return {
                ...state,
                HashTagList : temp
            }
        }

        case 'CLEARFILTERDATA': {
            return {
                ...state,
                flagFilter:{
                    flagCount: '',
                    sortingOrder:''
                },
                lastLoggedPhy:{},
                addOnPhy:{},
            }
        }

        default:
            return state;
    }

}